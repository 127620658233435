import React, { useContext, useEffect, useRef } from "react";
import "./ContainerChat.css";
import Container from "@mui/material/Container";
import MessageChatBotList from "../../Components/MessageChatBotList/MessageChatBotList";
import MessageUser from "../../Components/MessageUser/MessageUser";
import { GlobalContext } from "../../Context/GlobalContext";
import { WIDGET_MODE } from "../../Constants/Global";
import { checkDeviceType, scrollDownContainer } from "../../Utils/Utils";
import Typing from "../../Components/Typing/Typing";

/**
 * Componente contenedor del historial del chat: <br>
 * Contiene todos los bocadillos del chatbot y del usuario.
 * @class Layout/ContainerChat
 * @example
 * import ContainerChat from '../Layout/ContainerChat/ContainerChat';
 * <ContainerChat />
 * @returns {React.Component} ContainerChat
 */

function ContainerChat() {
  const { cssApi, customApi, chatMessages, setChatProgress, isTyping } = useContext(GlobalContext);

  const ref = useRef(null);

  useEffect(() => {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            scrollDownContainer(mutation.target.parentNode);
            // Wait to images are loaded
            if(mutation.target.lastChild){
              const images = mutation.target.lastChild.querySelectorAll('.MessageChatBot img');
              images.forEach((img) => {
                img.addEventListener('load', ()=>scrollDownContainer(mutation.target.parentNode, 2000))
              });
            }
          }
        }
      });

      if (ref.current) {
        observer.observe(ref.current, { childList: true });

        // Formazos scroll cuando esta protegido porque a veces, no sabemos cuando al cargar o algo se sube arriba y no permite bajar
        if(customApi.ui_protected){
          setInterval(() => {
              scrollDownContainer(ref.current.parentNode);
            },
            3000);

        }
      }


    return () => {
      observer.disconnect();
    };

  }, []);
  /**
   * Comprueba si es un dispotivo móvil para restar el alto del input y prevenir que la barra del navegador lo oculte
   * @returns {*} valor de alto calculado
   */
  const calcHeight = () => {
    if (WIDGET_MODE) {
      switch (checkDeviceType()) {        
        case "iPhone":
          return "calc(100vh - 290px)";
        default:
          return "480px";
      }
   
    } else {
      switch (checkDeviceType()) {
        case "Android":
          return "calc(100vh - 172px)";
        case "iPhone":
          return "calc(100vh - 28vh)";
        default:
          return "calc(100vh - 117px)";
      }
    }
  };

  /**
   * Valida al momento de un select o carousel cambia el alto de la caja
   * @param {*} target Elemento que dispara el evento.
   */
  const excludeTarget = (target) => {
    return (
      target.classList.contains("MuiTouchRipple-root") ||
      target.classList.contains("MuiButton-root") ||
      target.classList.contains("MuiButtonBase-root") ||
      target.classList.length === 0
    );
  };

  /**
   * Estilo css del contenedor del chat.
   */
  const ContainerStyle = {
    height: calcHeight(),
    bgcolor: cssApi.background,
    flexGrow: 1,
  };

  return (
    <div className={`chatbothr_containerchat ${customApi.ui_protected ? "uip" : ""}`} style={ContainerStyle}>
      <Container ref={ref} fixed maxWidth="sm" className="MessageListContainer">
        {chatMessages.map((msg, index) =>
          msg.typeUser === 1 ? (
            <MessageChatBotList
              chatbotMsg={msg}
              setChatProgress={setChatProgress}
              key={index.toString()}
            />
          ) : (
            <MessageUser msg={msg} key={index.toString()} />
          )
        )}{
          isTyping && (
              <Typing key={chatMessages.length} />
          )
        }
      </Container>
    </div>
  );
}

export default ContainerChat;
